
	frappe.templates['sidebar'] = `<div class="body-sidebar-container">
	<div class="body-sidebar-placeholder"></div>
		<div class="body-sidebar">
			<div class="body-sidebar-top">
				<div class="sidebar-items">
				</div>
			</div>
			<div class="body-sidebar-bottom">
				<a class="collapse-sidebar-link">
					<svg class="icon" style="margin-top: -2px">
						<use href="#icon-arrow-right-to-line"></use></svg> {%= __("Collapse") %}</a>
			</div>
		</div>

	</div>
</div>
`;
